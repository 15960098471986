const Trot = () => {
  return (
    <>
      <h7>Définition</h7>
      <p>Le trot est une allure dite à 2 temps :</p>

      <ul>
        <li>Le 1er temps correspond au poser d'un bipède diagonal (antérieur droit et postérieur gauche levés par exemple) + un moment de projection</li>
        <li>Le 2e temps correspond au poser de l'autre bipède diagonal (antérieur gauche et postérieur droit levés) + le moment de projection.</li>
      </ul>

      <h7>L'équitation du trot</h7>

      <ul>
        <li>Le trot assis</li>
        <li>Le trot enlevé</li>
      </ul>
    </>
  );
}

export default Trot;
