const Galop = () => {
  return (
    <>
      <h7>Le mécanisme du galop</h7>

      <p>Le galop est dit asymétrique car le poser des membres est différent selon que le cheval galope à droite ou à gauche. Par exemple, à une phase (temps) du galop, tout le corps du cheval repose sur un seul antérieur : l'antérieur droit pour le galop à droite ou l'antérieur gauche pour le galop à gauche. Le mécanisme du galop à droite est :</p>

      <ul>
        <li>1er temps : pose du postérieur gauche</li>
        <li>2e temps : pose du bipède diagonal gauche (antérieur gauche et postérieur droit)</li>
        <li>3e temps : pose de l'antérieur droit</li>
        <li>phase de projection</li>
      </ul>

      <p>Pour le galop à gauche, le mécanisme est identique, mais en inversant la droite et la gauche.</p>
    </>
  );
}

export default Galop;
