import './App.css';
import WebApp from './screens/WebApp';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { useEffect, useState } from 'react';
import CookieConsent from "react-cookie-consent";

library.add(fas, far, fab);

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <>
      <CookieConsent
        location="bottom"
        buttonText="J'ai compris !"
        cookieName="cookiesRGPD"
        style={{ background: "#7D4427" }}
        buttonStyle={{ background: "#A2C523", color: "#7D4427", fontSize: "13px", fontWeight: "bold" }}
        expires={150}
      >
        <span style={{ fontSize: "14px" }}>Afin d'améliorer l'expérience utilisateur, notre site <b>CHR 44</b> utilise des cookies. En continuant la navigation dessus, vous accepterez leurs conditions de stockage et d'exploitation définies par le règlement général <b>RGPD.</b></span>
      </CookieConsent>

      <WebApp />
    </>
  );
}

export default App;
