const EngagementForm = () => {
  return (
    <form className="custom-form engagement-form mb-5 mb-lg-0" role="form">
      <div className="contact-form-body">
        <div className="row">
          
          <div className="col-12 mb-3">
            <p className="mb-1">Votre demande concerne : </p>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
              <label className="form-check-label" htmlFor="inlineRadio1">Adhésion</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
              <label className="form-check-label" htmlFor="inlineRadio2">Partenariat</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" />
              <label className="form-check-label" htmlFor="inlineRadio3">Bénévolat</label>
            </div>
          </div>
          
        </div>
        <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
                <input type="text" name="engagement-name" id="engagement-id" className="form-control" placeholder="Nom complet" required  />
            </div>

            <div className="col-lg-6 col-md-6 col-12">
                <input type="email" name="engagement-email" id="engagement-email"
                    pattern="[^ @]*@[^ @]*" className="form-control"
                    placeholder="Adresse e-mail" required />
            </div>
        </div>

        <input type="text" name="engagement-company" id="engagement-company"
            className="form-control" placeholder="Entreprise / Association" required />

        <textarea name="engagement-message" rows="5" className="form-control" required
            id="engagement-message" placeholder="Message"></textarea>

        <div className="col-lg-4 col-md-10 col-8 mx-auto">
            <button type="submit" className="form-control custom-btn"><i className="fas fa-paper-plane"></i> Envoyer</button>
        </div>
      </div>
    </form>
  )
}

export default EngagementForm;
