import axios from 'axios';

class AxiosService {

  constructor() {
    // Créer une instance axios avec une configuration de base
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL, // Remplacez par l'URL de votre API
      timeout: 10000, // Temps d'attente avant l'échec de la requête (en ms)
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Intercepteurs pour ajouter des headers ou gérer les erreurs
    instance.interceptors.request.use(this.handleRequest, this.handleError);
    instance.interceptors.response.use(this.handleResponse, this.handleError);

    this.instance = instance;
  }

  // Intercepteur pour les requêtes
  handleRequest(config) {
    // Ajouter un token si besoin
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }

  // Intercepteur pour les réponses
  handleResponse(response) {
    return response;
  }

  // Gestion des erreurs
  handleError(error) {
    // Vous pouvez gérer des erreurs globales ici
    if (error.response) {
      console.error('Erreur de réponse', error.response);
    } else if (error.request) {
      console.error('Erreur de requête', error.request);
    } else {
      console.error('Erreur', error.message);
    }
    return Promise.reject(error);
  }

  // Méthodes pour les appels API

  get(url, params = {}) {
    return this.instance.get(url, { params });
  }

  post(url, data) {
    return this.instance.post(url, data);
  }

  put(url, data) {
    return this.instance.put(url, data);
  }

  delete(url) {
    return this.instance.delete(url);
  }
}

// On exporte une instance unique de ce service
const axiosService = new AxiosService();
export default axiosService;
