import Modal from 'react-bootstrap/Modal';

const ModalUI = ({ show, handleClose, title, closeButton, children }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{ title }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { children }
      </Modal.Body>
      <Modal.Footer>
        { closeButton && <button className="form-control custom-btn" onClick={handleClose}><i className="fas fa-close"></i> Fermer</button> }
      </Modal.Footer>
    </Modal>
  );
}

export default ModalUI;
