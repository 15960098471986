const Attelage = () => {
  return (
    <>
      <h7>Définition</h7>

      <p>L'attelage est un sport équestre reconnu par la Fédération Équestre Internationale.</p>
      
      <p>Un attelage de compétition est composé d'une voiture, d'un, deux ou quatre chevaux et d'un meneur aidé par des grooms. La voiture utilisée est dédiée spécifiquement à ce sport et est munie de freins à disque sur les voitures modernes avec des brancards articulés sur les modèles à 4 roues.</p>

      <p>Tous les chevaux peuvent être attelés, mais certaines races ont des prédispositions particulières à cette discipline. Certains attelages, y compris à haut niveau, peuvent présenter une combinaison étonnante de chevaux dont certains galopent tandis que d'autres trottent. L'important pour le meneur est de conserver la maîtrise de son attelage.</p>
    </>
  );
}

export default Attelage;
