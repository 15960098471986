const Driver = () => {
  return (
    <>
      <h7>Définition</h7>

      <p>Driver est un mot anglais qui peut signifier « pilote » ou « conducteur » (du verbe anglais to drive). En français, il est également employé dans les domaines suivants (anglicisme) :</p>

      <p>En sport hippique, un « driver » est une personne dirigeant son cheval dans le cas du trot attelé.</p>

      <p>En France, les drivers et jockeys remportant le plus de courses dans l'année reçoivent des récompenses. Il s'agit du Sulky d'or pour les drivers, de l'Étrier d'or pour les jockeys de trot monté et de la Cravache d'or pour les jockeys de plat et d'obstacle.</p>

    </>
  );
}

export default Driver;
